var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/coaching-challenge"
    }
  }, [_vm._v(" Manajemen Diet Coaching & Challenge ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Detail Diet Coaching & Challenge ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Konten ")]), _c('c-box', [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "h": "auto",
      "min-w": "114px",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "as": "router-link",
      "to": {
        name: 'superadmin.coaching-challenge.edit',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Edit ")], 1)], 1)], 1), _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Tipe ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.type) + " ")])], 1), _vm.item.type === 'daily_coaching' ? _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Tampil Hari Ke ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" Hari ke-" + _vm._s(_vm.item.showOnDay) + " ")])], 1) : _vm._e(), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Judul ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-weight": "500",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kategori ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.category) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Deskripsi ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "word-break": "break-word"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.item.description)
    }
  })], 1), _vm.imagePreview.length > 0 ? _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Preview Cover Program ")]), _vm._l(_vm.imagePreview, function (item, index) {
    return _c('c-flex', {
      key: 'preview-' + index,
      attrs: {
        "mb": "28px"
      }
    }, [_c('c-box', {
      attrs: {
        "mr": "30",
        "min-w": "230px",
        "w": "230px"
      }
    }, [_c('c-image', {
      attrs: {
        "src": _vm.getPhotoProgram(item.url),
        "alt": "file program",
        "mx": "auto",
        "border-radius": "6px"
      }
    })], 1)], 1);
  })], 2) : _vm._e(), _vm.images.length > 0 ? _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Gambar dan Caption ")]), _vm._l(_vm.images, function (item, index) {
    return _c('c-flex', {
      key: 'img-' + index,
      attrs: {
        "mb": "28px"
      }
    }, [_c('c-box', {
      attrs: {
        "mr": "30",
        "min-w": "230px",
        "w": "230px"
      }
    }, [_c('c-image', {
      attrs: {
        "src": _vm.getPhotoProgram(item.url),
        "alt": "file program",
        "mx": "auto",
        "border-radius": "6px"
      }
    })], 1), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-weight": "normal",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(item.caption) + " ")])], 1);
  })], 2) : _vm._e(), _vm.videos.length > 0 ? _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Video dan Caption ")]), _vm._l(_vm.videos, function (item, index) {
    return _c('c-flex', {
      key: 'video-' + index,
      attrs: {
        "mb": "28px"
      }
    }, [_c('c-box', {
      attrs: {
        "mr": "30",
        "min-w": "387px",
        "min-h": "218px"
      }
    }, [_c('video', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "w": "100%",
        "h": "100%",
        "src": item.url,
        "controls": "",
        "muted": "muted"
      },
      domProps: {
        "muted": true
      }
    })]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-weight": "normal",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(item.caption) + " ")])], 1);
  })], 2) : _vm._e(), _vm.youtube.length > 0 ? _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" YouTube ")]), _vm._l(_vm.youtube, function (item, index) {
    return _c('c-box', {
      key: 'youtube-' + index,
      attrs: {
        "mb": "28px",
        "mr": "30"
      }
    }, [_c('youtube', {
      attrs: {
        "video-id": _vm.getYoutubeId(item.url)
      }
    })], 1);
  })], 2) : _vm._e(), _vm.files.length > 0 ? _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" File dan Dokumen ")]), _vm._l(_vm.files, function (item, index) {
    return _c('c-flex', {
      key: 'file-' + index,
      attrs: {
        "mb": "28px"
      }
    }, [_c('c-flex', {
      attrs: {
        "align-items": "center",
        "justify-content": "center",
        "h": "100px",
        "w": "100px",
        "mr": "20px",
        "bg": "#F4CC4619",
        "border-radius": "12px"
      }
    }, [_c('c-image', {
      attrs: {
        "w": "50px",
        "h": "50px",
        "src": require('@/assets/icon-folder.svg'),
        "alt": "icon-folder"
      }
    })], 1), _c('c-flex', {
      attrs: {
        "justify-content": "center",
        "align-items": "baseline",
        "flex-dir": "column",
        "flex": "1"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "24px",
        "line-height": "150%",
        "text-align": "justify",
        "color": "#333333"
      }
    }, [_vm._v(" " + _vm._s(item.fileName) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "18px",
        "line-height": "150%",
        "color": "#888888"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatBytes(item.fileSize)) + " ")])], 1)], 1);
  })], 2) : _vm._e()], 1), _c('DeleteConfirm', {
    attrs: {
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }